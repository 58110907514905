.question {
  font-weight: bold;
  font-size: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.customerServiceInfo {
  margin: 0;
}

.showOnMobile {
  display: none;
}

.assistanceFlex {
  display: flex;
  margin-bottom: 1em;
}

.assistanceImage {
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 455px) {
  .assistanceImage {
    display: none;
  }

  .phone {
    display: none;
  }

  .showOnMobile {
    display: block;
  }
}

@media screen and (max-width: 312px) {
  .email {
    font-size: 0.8em;
  }

  .phone {
    font-size: 0.8em;
  }

  .showOnMobile {
    font-size: 0.8em;
  }

  .customerServiceInfo {
    font-size: 0.8em;
  }
}
