.Welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem;
  background-color: #186e93;
}

.Welcome__inner {
  font-weight: bold;
  font-size: 2em;
  color: #fff;
  margin: auto;
  max-width: 480px;
  text-align: center;
}

.Welcome__actions {
  margin-top: 1em;

  button {
    margin: 0.25em;
  }
}


.password-criteria ul {
    list-style: none;
    padding: 0;
    margin-top: 10px;
    font-size: 12px;
}

.password-criteria li {
    margin-bottom: 5px;
}

.text-success {
    color: green;
}

.text-danger {
    color: red;
}

.password-input-wrapper {
    position: relative;
    align-items: center;
}

.password-toggle {
    margin-left: 10px;
}

.password-strength {
    margin-top: 10px;
}

.strength-bar {
    height: 5px;
    background-color: #d3d3d3;
    transition: width 0.3s ease-in-out;
}

.strength-weak .strength-bar {
    background-color: red;
}

.strength-medium .strength-bar {
    background-color: orange;
}

.strength-strong .strength-bar {
    background-color: green;
}



.password-rules-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.password-rules-list li {
    font-size: 14px;
    margin-bottom: 5px;
}
.tooltip {
    background-color: white !important;
    opacity: 1 !important; 
}
.tooltip-inner {
    background-color: white;
    color: #000 !important;
    opacity: 1 !important;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.4);
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
    border-top-color: white !important;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.4) !important
}