$orange: #fe7800 !default;
$green:  #60cb62 !default;
$blue:   #467cbf !default;

@import "node_modules/bootstrap/scss/bootstrap";
@import "_portal";

label {
  font-weight: bold;
  &.form-check-label {
    font-weight: inherit;
  }
}

label, legend {
  font-size: 1rem;
  font-weight: bold;
}
