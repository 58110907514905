.RemindMeLater {
    display: flex;
    direction: row;
    width: 100%;
}

.FooterQuestion {
    font-weight: bold;
    margin: 0;
}

.FooterAssurance {
    margin: 0;
}

