$portal-menu-link: #eee !default;
$portal-menu-link-bg: #444 !default;
$portal-menu-link-hover: #fff !default;
$portal-menu-link-bg-hover: #555 !default;
$portal-menu-link-active: #fff !default;
$portal-menu-link-bg-active: #0f6d95 !default;
$portal-nav-bg-color: #222 !default;
$portal-menu-header-bg: #333 !default;
$portal-menu-header: #ccc !default;
$portal-menu-button: #ccc !default;
$portal-user-nav-bg: #333 !default;
$portal-user-nav-text: #fff !default;
$portal-sidenav-bg: #f3f3f3 !default;
$portal-body-bg: #fff !default;
$portal-menu-link-shadow: true !default;

.PortalWrapper {
  @media (min-width: 768px) {
    transition: none;
    margin-left: 250px;
  }
}

.PortalNavMainToggle {
  margin: 0 1rem;

  @media (min-width: 768px) {
    display: none;
  }
}

.PortalNavMainBranding {
  margin: 0 1rem;
  @media (min-width: 768px) {
    display: none;
  }
  @media (max-width: 767px) {
    flex-grow: 1;
  }
  a {
    display: block;
    width: 146px;
    height: 32px;
    margin: auto;
    color: black;
  }
}

.PortalNavMainTools {
  margin: 0 1rem;
  flex-grow: 1;
  text-align: right;

  @media (max-width: 767px) {
    display: none;
  }
}

.PortalNavMainUser {
  margin: 0 1rem;

  > span {
    margin-right: 0.5rem;

    @media (max-width: 767px) {
      display: none;
    }
  }
}

.PortalNavAppClose {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 1.25rem;
  background: transparent !important;
  border: none !important;
  color: #fff !important;

  @media (min-width: 768px) {
    display: none;
  }
}

.PortalNavAppBranding {
  display: block;
  width: 146px;
  height: 32px;
  color: #fff;

  @media (min-width: 768px) {
    color: #000;
  }
}
.PortalNavUserClose {
  position: absolute;
  top: 0.825rem;
  left: 0.5rem;
  font-size: 1.25rem;
  background: transparent !important;
  border: none !important;
  color: #fff !important;
}

.PortalNavBlocker {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100500;
  background: rgba(0, 0, 0, 0.3);

  @media (min-width: 768px) {
    display: none;
  }
}

.PortalFooter {
  padding: 0.5rem 2rem;
  background: #aaa;
  color: #333;
  font-size: 0.75rem;
}

.PortalDrawer {
  display: flex;
  flex-direction: column;
  width: 250px;
}

.PortalBody {
  background: $portal-body-bg;
}

.PortalDrawerHeader {
  padding: 4rem 1rem 1rem;
}

.PortalDrawerBody {
  flex-grow: 1;
}

.PortalDrawerFooter {
  padding: 1.25rem;
}

.PortalNavUser {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 101000;
  display: flex;
  transform: translateX(260px);
  transition: transform 200ms ease-in-out;
  background: $portal-user-nav-bg;
  color: $portal-user-nav-text;

  &--open {
    transform: translateX(0);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
}

.PortalNavMain {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background: transparent;
  transition: transform 0.2s ease, background 0.2s ease, box-shadow 0.2s ease;

  @media (min-width: 768px) {
    margin-left: 250px;
  }

  &--background {
    position: fixed;
    top: -60px;
    transform: translateY(60px);
    background: $portal-body-bg;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 767px) {
    position: fixed;
    background: #fff;
    top: 0;
    transform: translateY(0);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  }
}

.PortalNavApp {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 101000;
  display: flex;
  transform: translateX(-260px);
  overflow-x: hidden;
  overflow-y: auto;

  &--open {
    transform: translateX(0);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 767px) {
    transition: transform 0.2s ease-in-out;
    background: #333;
    color: white;
  }

  @media (min-width: 768px) {
    transform: translateX(0);
    background-color: $portal-sidenav-bg;
    color: $portal-nav-bg-color;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 100000;
      width: 0.5rem;
      pointer-events: none;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
      border-right: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
}

.PortalMenu {
  position: relative;
  background: $portal-nav-bg-color;
  opacity: 1;
  overflow: hidden;
  &__header {
    padding: 0.5em 16px;
    background: $portal-menu-header-bg;
    color: $portal-menu-header;
    font-size: 1.125rem;
    font-weight: 300;
  }
  &__item {
    margin: 1px 0;
    &--indent {
      padding-left: 30px;
    }
    button {
      border: 0;
      text-align: left;
      width: 100%;
    }
  }
  &__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 16px;
    background: $portal-menu-link-bg;
    color: $portal-menu-link;
    text-decoration: none;
    font-size: 0.875em;
    transition: background-color 0.2s ease;
    @if $portal-menu-link-shadow {
      text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
    }

    &:hover,
    &:active,
    &:focus {
      color: $portal-menu-link-hover;
      background: $portal-menu-link-bg-hover;
      text-decoration: none;
    }

    > span + span {
      margin-left: 10px;
      color: #999;
    }

    &.is-active {
      color: $portal-menu-link-active;
      background-color: $portal-menu-link-bg-active;
      pointer-events: none;
    }
  }
}

.MenuItems {
  position: absolute;
  top: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  background-color: $portal-nav-bg-color;
  opacity: 0;
  transition: opacity 0.2s ease;
  &--active {
    position: relative;
    opacity: 1;
  }
}

.MenuSubhead {
  margin: 1px 0;
  padding: 0.5em 16px;
  text-shadow: 1px 1px 0 fade(black, 30%);
  background: #666;
  color: #ccc;
  text-transform: uppercase;
  font-size: 0.825em;
}

.MenuNav {
  display: flex;
  justify-content: space-between;
  padding: 0.825em 16px;
}

.MenuButton {
  background: transparent;
  border: 0;
  color: $portal-menu-button;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.825em;
  padding: 0;
}

.IconButton {
  margin: 0 1px;
  background: #fff;
  border: 1px solid #999;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  &--borderless {
    border-color: transparent;
  }
}

.PortalNavBodyContent {
  padding: 20px;
}
