.AnimatedSwitch {
  position: relative;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;

  > div {
    position: absolute;
    top: 0;
    width: 100%;
  }

  > div:nth-child(1) {
    position: relative;
  }
}
