.AuthWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    min-height: 100vh;
    background-color: #f5f7fa;

    @media (min-width: 960px) {
        padding: 2rem;
    }

    @media (min-width: 480px) and (max-width: 959px) {
        padding: 1rem;
    }

    &__container {
        max-width: 550px;
        width: 100%;

        &.is-large {
            max-width: 700px;
        }
    }

    &__panel {
        position: relative;
        padding: 2rem;
        padding-top: 1.85em;
        background: #fff;
        border: 1px solid #cfdbe2;
        border-radius: 4px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
    }

    &__primary {
        flex-grow: 1;
    }

    &__secondary {
        width: 280px;
        margin-left: auto;

        @media (max-width: 767px) {
            display: none;
        }
    }

    &__branding {
        margin-bottom: 1rem;
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;

        > svg {
            width: 150px;
            height: 33px;
        }
    }

    &__content-action {
        margin-bottom: 1em;
    }

    &__title {
        font-size: 1.5em;
        margin-bottom: 0;
    }

    &__register__title {
        font-size: 1.5em;
        margin-top: 0.5rem;
        margin-bottom: 0;
        font-weight: bold;
    }

    &__redirect {
        font-size: 1em;
        margin-top: 0;
    }

    &__content {
        margin-top: 2rem;
        // TODO: REMOVE HACK AFTER HPCL UPDATE
        .form-group {
            label {
                font-size: 1em;
            }

            legend {
                font-size: 1em;
                font-weight: bold;
            }
        }

        .is-invalid + .input-group-prepend button {
            border-color: #dc3545 !important;
        }

        &-headline {
            font-size: 1.5em;
            font-weight: bold;
        }
    }

    &__register {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        // TODO: REMOVE HACK AFTER HPCL UPDATE
        .form-group {
            label {
                font-size: 1em;
            }

            legend {
                font-size: 1em;
                font-weight: bold;
            }
        }

        .is-invalid + .input-group-prepend button {
            border-color: #dc3545 !important;
        }

        &-headline {
            font-size: 1.5em;
            font-weight: bold;
        }
    }

    &__register-confirm-method {
        .form-check {
            display: inline-block;

            + .form-check {
                margin-left: 24px;
            }
        }

        .form-check-input {
            margin-top: 2px;
        }
    }

    &__register-actions {
        a {
            margin-left: 10px;
        }

        @media (max-width: 575px) {
            width: 100%;
            margin-top: 1rem;
            padding-top: 1rem;
            border: 0;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
        }
    }

    &__footer {
        text-align: center;
        border-top: 1px solid #ddd;
        margin: 2rem -2rem -1rem;
        padding: 1em;
        color: rgba(0, 0, 0, 0.7);

        span {
            display: inline-block;
        }
    }

    &__logout {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
}

.SignUp .AuthWrapper__content {
    margin-top: 0;
}
